module.exports = color =>
	`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
	<g>
		<polygon style="fill:${color || '#4FBA6F'};" points="512,256 317.793,114.759 317.793,211.862 211.862,211.862 211.862,300.138 317.793,300.138
			317.793,397.241 	"/>
		<path style="fill:${color || '#4FBA6F'};" d="M158.897,211.862c-9.71,0-17.655,7.945-17.655,17.655v52.966c0,9.71,7.945,17.655,17.655,17.655
			c9.71,0,17.655-7.945,17.655-17.655v-52.966C176.552,219.807,168.607,211.862,158.897,211.862"/>
		<path style="fill:${color || '#4FBA6F'};" d="M88.276,211.862c-9.71,0-17.655,7.945-17.655,17.655v52.966c0,9.71,7.945,17.655,17.655,17.655
			s17.655-7.945,17.655-17.655v-52.966C105.931,219.807,97.986,211.862,88.276,211.862"/>
		<path style="fill:${color || '#4FBA6F'};" d="M17.655,211.862C7.945,211.862,0,219.807,0,229.517v52.966c0,9.71,7.945,17.655,17.655,17.655
			s17.655-7.945,17.655-17.655v-52.966C35.31,219.807,27.366,211.862,17.655,211.862"/>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	</svg>`;
