import React, { Fragment } from 'react';

const Contact = (props) => {

	return (<Fragment>
		<h3>
			Mis leht see on?
		</h3>
		<p>surf.paper.ee lehekülg on mõeldud lihtsustamaks surfarite igapäevast küsimust — kus on tuul ja parim surfiilm?</p>
		<h3>
			Kes külastavad seda lehte?
		</h3>
		<p>Peamiselt surfarid, aga lisaks on päris palju muid ilmast huvitatuid: purjetajaid, meremehi jne.</p>
		<h3>
			Kontaktandmed
		</h3>
		<p>surf.paper.ee lehte haldab  Nikolai Rodin, <br/>niko.rodin@gmail.com</p>
		<h3>
			Tehnilised nõuded
		</h3>
		<p>Lehekülg peaks olema kasutatav kõikide uuemate enamkasutatavate brauseritega, juhul kui teil on mingeid probleeme, või mõni link ei tööta, siis võtke ühendust ülaloleval emailil.</p>
	</Fragment>);
};

export default Contact;
