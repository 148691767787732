import { get } from 'lodash';
import L from 'leaflet';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { getWeather, getGeoInfo } from '../api';
import arrow from '../utils/arrow';

import '../../../node_modules/leaflet/dist/leaflet.css';

const EntryPage = (props) => {
	const topBanner = get(props.banners, 'top', {});
	const bottomBanner = get(props.banners, 'bottom', {});
	const [position, setPosition] = useState({
		lat: 58.5953,
		lng: 25.0136,
		zoom: 7,
		located: false
	});
	const [weatherData, setWeatherData] = useState({});
	const [bannersHeight, setBannersHeight] = useState(0);
	const [bannersLoaded, setBannersLoaded] = useState([]);

	useEffect(() => {
		async function fetchWeather() {
			const data = await getWeather();

			if (data) {
				setWeatherData(data);
				const geoData = await getGeoInfo();

				if (geoData && geoData.latitude && geoData.longitude) {
					setPosition({
						lat: geoData.latitude,
						lng: geoData.longitude,
						zoom: 8,
						located: true
					});
				}
			}
		}

		fetchWeather();
	}, []);


	function getPopoverContent(station, timestamp) {
		return (<div className="lefa-popover">
			<h3>{station.name[0]}</h3>
			<span>Tuul: <strong>{station.windspeed[0]} ({station.windspeedmax[0]}) m/s</strong></span>
			<span>Temperatuur: <strong>{station.airtemperature[0] || '-'} C</strong></span>
			<span>Vesi: <strong>{station.watertemperature[0] || '-'} C</strong></span>
			<small>{moment.unix(timestamp).format('DD.MM.YYYY HH:mm')}</small>
		</div>);
	}

	function onBannerLoad(ev) {
		const newHeight = bannersHeight + (ev.target.offsetHeight + 20);

		setBannersHeight(newHeight);

		const loaded = [...bannersLoaded];
		loaded.push(ev.target.id);

		setBannersLoaded(loaded);
	}

	function areBannersLoaded() {
		return (!topBanner.active || (topBanner.active && bannersLoaded.includes('top'))) &&
		(!bottomBanner.active || (bottomBanner.active && bannersLoaded.includes('bottom')));
	}

	function getMarkers() {
		const timestamp = get(weatherData, 'observations.$.timestamp');
		const stations = get(weatherData, 'observations.station', []);

		return stations.map((station, i) => {
			if (!station.windspeed[0]) {
				return null;
			}

			function getWindConditions() {
				if (Number(station.windspeed[0]) >= 8) {
					return { color: '#4FBA6F', class: '' };
				} else if (Number(station.windspeed[0]) >= 6) {
					return { color: '#F8C53A', class: 'moderate' };
				}

				return { color: '#dc3545', class: 'low' };
			}

			const conditions = getWindConditions();


			const icon = new L.DivIcon({
				className: 'lefa-icon',
				html: `<div style="transform: rotate(${90 + Number(station.winddirection[0])}deg);" class="lefa-svg ${conditions.class}">
					${arrow(conditions.color)}</div>
					<span class="lefa-icon-text ${conditions.class}">${station.windspeed[0]} <small>m/s</small></span>`
			});

			const location = [Number(station.latitude[0]), Number(station.longitude[0])];

			return (<Marker
				key={i}
				icon={icon}
				position={location}>
				<Popup offset={[12.5, 0]}>
					{getPopoverContent(station, timestamp)}
				</Popup>
			</Marker>);
		});
	}

	return (<div className="entry-page" style={{ padding: 20, width: props.measures.contentWidth, height: props.measures.contentHeight }}>
		{topBanner.active ?
			<a href={topBanner.link} target="_blank">
				<img id="top" onLoad={onBannerLoad} style={{ width: '100%', maxWidth: 720, marginBottom: 20, flex: 1 }} src="/api/banner/top" />
			</a> : null }
		<div className="entry-wrapper">
			{areBannersLoaded() && <Map zoomControl={false} center={[position.lat, position.lng]} style={{ height: props.measures.contentHeight - (bannersHeight + 40), minHeight: 300, width: '100%' }} zoom={position.zoom}>
				<TileLayer
					tms={true}
					continuousWorld={true}
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors <a href="https://www.maaamet.ee/et">Maa-amet</a>'
					url="https://tiles.maaamet.ee/tm/tms/1.0.0/kaart@GMC/{z}/{x}/{y}.png"
				/>
				{getMarkers()}
			</Map>}
		</div>
		{bottomBanner.active ?
			<a href={bottomBanner.link} target="_blank">
				<img id="bottom" onLoad={onBannerLoad} style={{ width: '100%', maxWidth: 720, margin: '20px 0px 0px', flex: 1 }} src="/api/banner/bottom" />
			</a> : null }
	</div>);
};

export default EntryPage;

