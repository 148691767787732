import 'whatwg-fetch';

const apiRequest = async (url, reqOpts) => {
	const response = await fetch(url, reqOpts);

	if (response.ok) return response.json();

	throw new Error(response);
};

const getDefaultReqOpts = () => {
	return {
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache-control': 'no-cache',
			'Pragma': 'no-cache'
		}
	};
};

const apiGet = async (url) => {
	const reqOpts = getDefaultReqOpts();
	const req = await apiRequest(url, reqOpts);
	return req;
};

const apiPut = async (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'PUT';
	reqOpts.body = JSON.stringify(options.payload);

	const req = await apiRequest(url, reqOpts);

	return req;
};

const apiPost = async (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'POST';
	reqOpts.body = JSON.stringify(options.payload);

	const req = await apiRequest(url, reqOpts);

	return req;
};

const apiDelete = async (url) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'DELETE';
	const req = await apiRequest(url, reqOpts);

	return req;
};


export const apiLogin = async (options) => {
	const req = await apiPost('/auth/login', options);
	return req;
};

export const getLinks = async () => {
	const req = await apiGet('/api/links');
	return req;
};

export const getBanners = async () => {
	const req = await apiGet('/api/banners');
	return req;
};

export const getGeoInfo = async () => {
	const req = await apiGet('https://api.ipgeolocation.io/ipgeo?apiKey=4839858e0b2045479ca5388198a5dea7');
	return req;
};

export const getWeather = async () => {
	const req = await apiGet('/api/weather');
	return req;
};

export const getClicks = async () => {
	const req = await apiGet('/api/clicks');
	return req;
};

export const updateLinks = async (options) => {
	const req = await apiPut('/api/links', options);
	return req;
};

export const updateBanners = async (options) => {
	const req = await apiPut('/api/banners', options);
	return req;
};

export const apiClick = async (options) => {
	const req = await apiPost('/api/click', options);
	return req;
};


export const getWebcams = async () => {
	const req = await apiGet('/api/webcams');
	return req;
};

export const createWebcam = async (options) => {
	const req = await apiPost('/api/webcam', options);
	return req;
};

export const updateWebcam = async (id, options) => {
	const req = await apiPost(`/api/webcam/${id}`, options);
	return req;
};

export const deleteWebcam = async (id) => {
	const req = await apiPost(`/api/webcam/${id}`);
	return req;
};
