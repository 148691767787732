import React, { useEffect, useState, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Layout, Icon, Modal, Button, notification } from 'antd';
import { debounce } from 'lodash';
import { getLinks, apiClick, getBanners } from '../api';
import windsurf from '../../images/windsurf.svg';
import kitesurf from '../../images/kitesurf.svg';
import surfer from '../../images/surfer.svg';
import { findLinkByUid } from '../utils/helpers';
import Navigation from './Navigation';
import Contact from './Contact';
import EntryPage from './EntryPage';

import './Mainpage.scss';

const isMobile = () => {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true;
	}

	return false;
};

const getRandomInt = (max) => {
	return Math.floor(Math.random() * Math.floor(max));
};

const getLoadingIcon = () => {
	switch (getRandomInt(4)) {
	case 1:
		return windsurf;
	case 2:
		return surfer;
	case 3:
		return kitesurf;
	default:
		return windsurf;
	}
};

const addToFavorites = (uid) => {
	localStorage.setItem(uid, true);
};

const removeFromFavorites = (uid) => {
	localStorage.removeItem(uid);
};

const { Header, Content } = Layout;

const Iframe = (props) => {
	return (<Fragment>
		<iframe style={{ display: props.loaded ? 'block' : 'none' }} onLoad={() => {
			props.onLoaded(true);
		}} src={props.href}></iframe>
	</Fragment>);
};

const Mainpage = (props) => {
	const [links, setLinks] = useState([]);
	const [banners, setBanners] = useState({});
	const [activeLink, setActiveLink] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [isBroken, setBroken] = useState(true);
	const [showContactModal, toggleContactModal] = useState(false);
	const [isFavorite, setFavorite] = useState(false);
	const [collapsed, setCollapsed] = useState(true);
	const wrapperRef = useRef(null);
	const [windowHeight, setWindowHeight] = useState('100vh');
	const [measures, setMeasures] = useState({
		width: '100%',
		height: '100%',
		contentHeight: 0
	});

	function adjustMeasures() {
		setWindowHeight(document.body.offsetHeight);

		if (wrapperRef.current) {
			let width = isBroken ? document.body.offsetWidth : document.body.offsetWidth - 258;
			let height = document.body.offsetHeight - 50;
			const contentHeight = height;
			let scale = 1;

			let contentWidth = '100%';

			if (width > 1100) {
				width = '100%';
			} else {
				contentWidth = width;
				scale = width / 1100;
				width = 1100;
			}

			if (scale !== 1) {
				height /= scale;
			}

			const data = {
				width,
				height,
				contentWidth,
				contentHeight,
				WebkitTransform: `scale(${scale})`,
				WebkitTransformOrigin: '0 0'
			};

			if (isMobile()) {
				data.overflowY = 'scroll';
			}

			setMeasures(data);
		}
	}


	function checkForIosMessage() {
		// Detects if device is on iOS
		const userAgent = navigator.userAgent.toLowerCase();
		const isIos = () => {
			return /iphone|ipad|ipod/.test(userAgent);
		};
		const isAndroid = userAgent.indexOf('android') > -1;

		// Detects if device is in standalone mode
		const isInStandaloneMode = () => (('standalone' in window.navigator) && (window.navigator.standalone)) || window.matchMedia('(display-mode: standalone)').matches;

		// Checks if should display install popup notification:
		if (isIos() && !isInStandaloneMode()) {
			notification.open({
				message: 'Kasutad iPhone-i?',
				placement: 'bottomRight',
				duration: 10,
				description:
					'Mugavamaks kasutamiseks installi see leht enda telefoni. Ava alt ribalt menüü ning vajuta "Add to Home Screen"'
			});
		}

		if (isAndroid && !isInStandaloneMode()) {
			notification.open({
				message: 'Kasutad Android-i?',
				placement: 'bottomRight',
				duration: 10,
				description:
					'Mugavamaks kasutamiseks installi see leht enda telefoni. Ava browseri menüü ning vajuta "Add to Home Screen"'
			});
		}
	}


	useEffect(() => {
		async function fetchData() {
			const l = await getLinks();
			setLinks(l);

			const b = await getBanners();
			setBanners(b);
		}
		fetchData();
	}, []);

	useEffect(() => {
		setActiveLink(findLinkByUid(links, props.path.params.uid) || {});
		setLoaded(false);
	}, [props.path.params.uid, links]);

	useEffect(() => {
		setFavorite(!!localStorage.getItem(activeLink.uid));

		if (!activeLink.href) {
			setLoaded(true);
		}
	}, [activeLink]);


	useEffect(() => {
		adjustMeasures();
		window.addEventListener('resize', debounce(adjustMeasures, 100));
	}, []);

	return (<Fragment>
		<Layout style={{ height: windowHeight, overflow: 'hidden' }} hasSider>
			<Navigation
				onLinkClick={() => {
					if (isBroken) {
						setCollapsed(true);
					}
				}}
				activeLink={activeLink}
				onBreakpoint={(broken) => {
					setBroken(broken);
				}}
				onCollapse={(isCollapsed) => {
					setCollapsed(isCollapsed);
				}}
				collapsed={collapsed}
				links={links}
			/>
			<Layout>
				<Header style={{
					background: '#fff', padding: 0, minWidth: 300, height: 50
				}} >
					{activeLink.uid ? <Icon onClick={() => {
						if (!isFavorite) {
							addToFavorites(activeLink.uid);
							setFavorite(true);
						} else {
							removeFromFavorites(activeLink.uid);
							setFavorite(false);
						}
					}} className="add-favorites" {...isFavorite ? { theme: 'filled' } : {} } type="star" /> :
						<Icon onClick={() => {
							toggleContactModal(true);
						}} className="info" type="question-circle" />}
				</Header>
				<Content>
					<div ref={wrapperRef} className="main-wrapper">
						{!loaded &&
							<img
								className="pulse"
								style={{ fontSize: 50, width: 100, marginTop: '100px' }}
								src={getLoadingIcon()} />}
						{activeLink.href ?
							<div className="iframe-box" style={measures}>
								<Iframe
									loaded={loaded}
									onLoaded={async () => {
										adjustMeasures();
										setLoaded(true);
										await apiClick({ payload: { uid: activeLink.uid } });
									}}
									href={activeLink.href}
								/>
							</div> :
							<EntryPage
								measures={measures}
								loaded={loaded}
								banners={banners}
							/>}
					</div>
				</Content>
			</Layout>
			<Modal
				visible={showContactModal}
				title="Info"
				onCancel={() => {
					toggleContactModal(false);
				}}
				footer={[
					<Button key="back" onClick={() => {
						toggleContactModal(false);
					}}>
						Sulge
					</Button>
				]}
			>
				<Contact />
			</Modal>
		</Layout>
	</Fragment>);
};

const MainRouter = () => {
	return (
		<Router>
			<Fragment>
				<Route exact path="" component={path => <Mainpage path={path.match} />} />
				<Route exact path="/:uid" component={path => <Mainpage path={path.match} />} />
			</Fragment>
		</Router>
	);
};

export default MainRouter;
