export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
};

export const move = (arr, oldIndex, newIndex) => {
	while (oldIndex < 0) {
		oldIndex += arr.length;
	}

	while (newIndex < 0) {
		newIndex += arr.length;
	}
	if (newIndex >= arr.length) {
		let k = newIndex - arr.length;
		while ((k--) + 1) {
			arr.push(undefined);
		}
	}

	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
	return arr;
};

export const findLinkByUid = (links, uid) => {
	let link;
	for (let i = 0; i < links.length; i++) {
		const subCategories = links[i].subCategories || [];
		if (link) {
			break;
		}
		for (let j = 0; j < subCategories.length; j++) {
			const results = subCategories[j].links || [];
			if (link) {
				break;
			}

			for (let k = 0; k < results.length; k++) {
				if (results[k].uid === uid) {
					link = results[k];
					break;
				}
			}
		}
	}

	return link;
};
