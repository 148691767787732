import React, { Fragment, useState } from 'react';
import { Layout, Tabs, Icon, Input } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';

import './Navigation.scss';

const { Sider } = Layout;
const { TabPane } = Tabs;

const categoryMatchesSearch = (category, keyword) => {
	if (!category.subCategories) {
		return false;
	}

	return category.subCategories.some((subCat) => {
		if (subCat.links &&
				!!subCat.links.find(l => l.title.toLowerCase().includes(keyword.toLowerCase()))) {
			return true;
		}

		return false;
	});
};

const categoryMatchesFavorite = (category) => {
	if (!category.subCategories) {
		return false;
	}

	return category.subCategories.some((subCat) => {
		if (subCat.links &&
				!!subCat.links.find(l => localStorage.getItem(l.uid))) {
			return true;
		}

		return false;
	});
};

const subCategoryMatchesSearch = (subCategory, searchKey) => {
	if (!subCategory.links) {
		return false;
	}

	return subCategory.links.some(link => link.title.toLowerCase().includes(searchKey.toLowerCase()));
};

const subCategoryMatchesFavorite = (subCategory) => {
	if (!subCategory.links) {
		return false;
	}

	return subCategory.links.some((link) => {
		return !!localStorage.getItem(link.uid);
	});
};

const Links = (props) => {
	return (<div className="links-wrapper">
		{props.links.map((category) => {
			const subCategories = category.subCategories || [];
			let match = props.searchKey ? categoryMatchesSearch(category, props.searchKey) : true;

			if (props.renderFavorites && match) {
				match = categoryMatchesFavorite(category);
			}

			return match && (<Fragment key={category.uid}><h4 className="category-title">{category.title}</h4>
				{subCategories.map((subCategory) => {
					const links = subCategory.links || [];
					let subMatch = props.searchKey ?
						subCategoryMatchesSearch(subCategory, props.searchKey) : true;

					if (props.renderFavorites && subMatch) {
						subMatch = subCategoryMatchesFavorite(subCategory);
					}

					return subMatch && (<Fragment key={subCategory.uid}>
						<h4 style={subCategory.invisible ? { display: 'none' } : {}} className="subCategory-title">
							{subCategory.title}
						</h4>
						<div className="links-container">

							{links.map((link) => {
								const isActive = props.activeLink ? props.activeLink.uid === link.uid : false;
								let linkMatch = props.searchKey ?
									link.title.toLowerCase().includes(props.searchKey.toLowerCase()) : true;

								if (props.renderFavorites && linkMatch) {
									linkMatch = !!localStorage.getItem(link.uid);
								}

								return linkMatch && (<Fragment key={link.uid}>
									{link.opensInWindow ?
										<a className="nav-link" href={link.href} target="_blank"> - {link.title}</a> :
										<Link className="nav-link" onClick={props.onLinkClick} to={`/${link.uid}`}>
											- {link.title}
											{isActive && <Icon type="caret-right" style={{
												float: 'right',
												fontSize: 22,
												color: '#F8C53A',
												position: 'absolute',
												right: 0
											}}/>}
										</Link>}
								</Fragment>);
							})}
						</div>
					</Fragment>);
				})}
			</Fragment>);
		})}
	</div>);
};

const Navigation = (props) => {
	const [searchKey, setSearchKey] = useState('');

	return (<Fragment>
		<Sider
			theme="light"
			breakpoint="lg"
			collapsedWidth="0"
			collapsed={props.collapsed}
			onBreakpoint={props.onBreakpoint}
			defaultCollapsed={true}
			onCollapse={(isCollapsed) => {
				props.onCollapse(isCollapsed);
			}}
			style={{
				height: '100%',
				backgroundColor: '#fafafa',
				WebkitOverflowScrolling: 'touch',
				transition: 'left right 200ms linear'
			}}
			width={258}
		>
			<div style={{ opacity: props.collapsed ? 0 : 1 }} className="nav-wrapper">
				<div className="main-logo">
					<Link to="/"><img src={logo} /></Link>
				</div>
				<Input style={{ marginBottom: 10 }} placeholder="Otsi" allowClear onChange={(ev) => { setSearchKey(ev.target.value); }}/>
				<Tabs tabBarStyle={{ width: '100%', margin: 0 }} type="card">
					<TabPane tab="KÕIK LINGID" key="1">
						<Links
							onLinkClick={props.onLinkClick}
							searchKey={searchKey}
							activeLink={props.activeLink}
							links={props.links}
						/>
					</TabPane>
					<TabPane tab="LEMMIKUD" key="2">
						<Links
							onLinkClick={props.onLinkClick}
							searchKey={searchKey}
							activeLink={props.activeLink}
							renderFavorites={true}
							links={props.links}
						/>
					</TabPane>
				</Tabs>
			</div>
		</Sider>
	</Fragment>);
};

export default Navigation;
